<template lang="pug">
  v-data-table.clickable(
    :headers="headers"
    :loading="areKitsPending"
    no-data-text="No Kits Found"
    :options.sync="options"
    :server-items-length="haveKitsLoaded ? paginationData.default.mostRecent.total : 0"
    :items="kits"
    @click:row="(item) => $router.push({name: 'kit', params: { kitId: item.id }})"
    :footer-props="{ itemsPerPageOptions: [5,10,15] }"
  )
    template(#body.prepend)
      tr
        td(colspan="100")
          v-btn(
            color="primary"
            block
            :to="{ name: 'edit-kit', params: { kitId: 'new' }, query: { shopId }}"
          )
            v-icon(
              left
            ) mdi-plus
            | Add Cost Recovery Parts Kit

    template(v-slot:item.actions="{ item: kit }")
        v-col.pa-0.actionsContainer
          v-btn.px-0(
            min-width="40px"
            @click.stop.prevent="deleteKit(kit)"
            color="white"
            elevation='0'
          )
            v-icon mdi-delete
          v-btn.pa-0(
            min-width="40px"
            @click.stop.prevent="(item) => $router.push({name: 'edit-kit', params: { kitId: kit.id }})"
            color="white"
            elevation='0'
          )
            v-icon.ma-0(
            ) mdi-pencil
</template>

<script>

import { useFind, useGet } from 'feathers-vuex'
import { computed, ref } from '@vue/composition-api'

export default {
  name: 'KitsTable',
  props: {
    query: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    headers: {
      type: Array,
      required: false,
      default: () => {
        return [
          {
            text: 'Title',
            value: 'title'
          },
          {
            text: 'Description',
            value: 'description'
          },
          {
            text: 'Actions',
            value: 'actions'
          }
        ]
      }
    },
    shopId: {
      type: String,
      required: false
    }
  },
  setup (props, context) {
    const { Kit } = context.root.$FeathersVuex.api

    // Setups up a reference that the search can v-model
    const search = ref(null)

    const { Shop } = context.root.$FeathersVuex.api

    const { item: shop } = useGet({
      model: Shop,
      id: props.shopId
    })

    // Basic starter datatable attributes
    const options = ref({
      page: 1,
      itemsPerPage: 10,
      sortBy: ['createdAt'],
      sortDesc: [true]
    })

    // Compute the sort based on various datatable properties
    const sortBy = computed(() => {
      var obj = {}
      if (options.value.sortBy && options.value.sortBy.length) {
        obj[options.value.sortBy[0]] = options.value.sortDesc[0] ? -1 : 1
      }
      return obj
    })

    const limit = computed(() => {
      if (options.value.itemsPerPage !== -1) {
        return options.value.itemsPerPage
      } else {
        return 999
      }
    })

    const queryObj = computed(() => {
      const returnQuery = {
        ...props.query,
        $limit: limit.value,
        $skip: options.value.itemsPerPage * (options.value.page - 1),
        $sort: sortBy.value
      }

      // Add a search term if it's not null, can $or multiple keys
      if (search.value !== null && search.value !== '') {
        returnQuery.$or = [
          {
            name: {
              $regex: search.value,
              $options: 'gi'
            }
          },
          {
            state: {
              $regex: search.value,
              $options: 'gi'
            }
          }
        ]
      }

      return returnQuery
    })

    // Construct the LOCAL params, these search the store
    const params = computed(() => {
      return { query: queryObj.value }
    })

    // Construct the fetch Params, these actually query the API, very confusing, I know
    const fetchParams = computed(() => {
      return { query: { ...queryObj.value } }
    })

    // Do the actual querying, be sure to remember to paginate
    const { items: kits, isPending: areKitsPending, paginationData, haveLoaded: haveKitsLoaded } = useFind({
      model: Kit,
      params,
      fetchParams,
      paginate: true
    })
    return {
      shop,
      search,
      options,
      sortBy,
      limit,
      kits,
      queryObj,
      params,
      fetchParams,
      areKitsPending,
      haveKitsLoaded,
      paginationData
    }
  },
  methods: {
    async deleteKit (kit) {
      try {
        const message = 'Are you sure you want to delete this Parts Kit?'
        const isYes = await this.$confirm(message, { title: 'Warning' })

        if (isYes) {
          await kit.remove(kit.id)

          this.$snackSuccess('Parts Kit Deleted')
        }
        if (this.user.isAdmin) {
          this.$router.push({ name: 'kits' })
        } else if (this.user.isRegionAdmin) {
          this.$router.push({ name: 'region', params: { regionId: this.user.region_id } })
        }
      } catch (error) {
        this.$logError(error)
      }
    }
  },
  data: () => ({

  })
}
</script>
<style lang="scss" scoped>
  .actionsContainer {
    min-width: 80px;
  }
</style>
